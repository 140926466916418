<template>
    <div class="exam client-content-padding-full">
        <SvgLoader v-if="loading" :loading="true" />
        <div v-else class="exam-content">
            <h1 class="module-name">
                {{ moduleName }} | {{ labels.exam }}
            </h1>
            <div v-if="Object.keys(currentQuestion).length" class="process">
                <BaseCard class="question">
                    <h2>
                        {{ labels.question }}
                    </h2>
                    <div v-html="currentQuestion.question"></div>
                </BaseCard>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-6 col-xl-4" v-for="(answer, answerIndex) in currentQuestion.answers" :key="`answer-${answerIndex}`">
                        <BaseCard @click.native="toggleAnswer(`${currentQuestion.id}.${answer.id}`)" class="answer">
                            <span v-html="answer.answer"></span>
                            <div class="selection">
                                <div v-if="isAnswerSelected(`${currentQuestion.id}.${answer.id}`)" class="selected"></div>
                            </div>
                        </BaseCard>
                    </div>
                </div>
                <div class="actions">
                    <button @click="closeTest" class="btn btn-danger">
                        {{ labels.close_test }}
                    </button>
                    <button v-if="onLastQuestion" class="btn btn-success" @click="submitExamFn">
                        {{ labels.submit_choice }}
                    </button>
                    <button v-else class="btn btn-primary" @click="selectQuestion(getNextQuestionId(currentQuestion.id))">
                        {{ labels.next_question }}
                    </button>
                </div>
            </div>
            <div v-if="Object.keys(examResult).length" class="finished">
                <BaseCard>
                    <div class="results">
                        <span>
                            {{ labels.correctly_answered }}: {{ examResult.totalCorrect }}
                        </span>
                        <span>
                            {{ labels.total_questions }}: {{ examResult.totalQuestions }}
                        </span>
                    </div>
                    <div class="final-text" v-html="examResult.afterExamText"></div>
                    <div class="actions">
                        <button @click="closeTest" class="btn btn-primary">
                            {{ labels.close_test }}
                        </button>
                    </div>
                </BaseCard>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";

    export default {
        name: 'ModuleExam',
        components: {
            SvgLoader: () => import('@/components/Preloaders/SvgLoader'),
            BaseCard: () => import('@/components/Cards/BaseCard.vue')
        },
        data() {
            return {
                moduleName: 'Module #1',
                // Example data
                // currentQuestion: {
                //     id: 1,
                //     question: 'Some question',
                //     answers: {
                //         id_1: 'Answer',
                //         id_2: 'Answer'
                //     }
                // },
                currentQuestion: {},
                allQuestions: [],
                selectedAnswers: [],
                examFinished: false,
                examResult: {
                    // totalQuestions: 999,
                    // totalCorrect: 999,
                    // afterExamText: '<h3>This is final text</h3>'
                },
                loading: true
            }
        },
        computed: {
            ...mapGetters("helper", ["labels"]),
            onLastQuestion() {
                if (Object.keys(this.currentQuestion).length) {
                    const questionIndex = this.allQuestions.findIndex(q => q.id == this.currentQuestion.id);

                    if (questionIndex == (this.allQuestions.length - 1)) return true;
                }

                return false;
            }
        },
        methods: {
            ...mapActions('account', ['getExam', 'submitExam']),
            ...mapActions('notification', ['addNotification']),
            getNextQuestionId(currentId) {
                const questionIndex = this.allQuestions.findIndex(q => q.id == currentId);

                if (questionIndex >= 0 && this.allQuestions[questionIndex + 1]) {
                    return this.allQuestions[questionIndex + 1].id;
                }

                return 0;
            },
            closeTest() {
                this.$router.push({
                    name: 'bundle-module',
                    params: {
                        monetization_bundle_id: this.$route.params.monetization_bundle_id,
                        module_id: this.$route.params.module_id
                    }
                });
            },
            selectQuestion(id) {
                const questionIndex = this.allQuestions.findIndex(q => q.id == id);
                if (questionIndex >= 0) {
                    this.currentQuestion = this.allQuestions[questionIndex];
                } else {
                    this.addNotification({
                        variant: "danger",
                        msg: 'no_exam_for_this_module',
                        labels: this.labels,
                        dismissSecs: 5
                    });

                    setTimeout(() => {
                        this.closeTest();
                    }, 5000);
                }
            },
            getExamFn() {
                this.loading = true;

                this.getExam(this.$route.params.module_id).then((res) => {
                    if (res.success) {
                        this.moduleName = res.data.module.title;

                        if (res.data?.module?.exam?.multipleChoiceTest) {
                            if (res.data.examResults) {
                                this.examResult = res.data.examResults
                            } else {
                                this.allQuestions = res.data.module.exam.questions;
                                this.selectQuestion(this.allQuestions[0].id);
                            }
                        } else {
                            this.addNotification({
                                variant: "danger",
                                msg: 'no_exam_for_this_module',
                                labels: this.labels,
                                dismissSecs: 5
                            });

                            setTimeout(() => {
                                this.closeTest();
                            }, 5000);
                        }
                    }
                }).catch((err) => console.log(err));

                this.loading = false;
            },
            submitExamFn() {
                this.loading = true;

                this.submitExam({
                    id: this.$route.params.module_id,
                    answers: this.selectedAnswers
                }).then((res) => {
                    if (res.success) {
                        this.currentQuestion = {};
                        this.examResult = res.data;
                    } else {
                        this.addNotification({
                            variant: "danger",
                            msg: res.error,
                            labels: this.labels
                        });
                    }
                }).catch((err) => console.log(err));

                this.loading = false;
            },
            isAnswerSelected(id) {
                if (this.selectedAnswers.includes(id)) return true;

                return false;
            },
            toggleAnswer(id) {
                if (this.selectedAnswers.includes(id)) {
                    const indexOfAnswer = this.selectedAnswers.indexOf(id);

                    if (indexOfAnswer >= 0) this.selectedAnswers.splice(indexOfAnswer, 1);
                } else {
                    this.selectedAnswers.push(id);
                }
            }
        },
        mounted() {
            this.getExamFn();
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/variables";

    .exam {
        min-height: 100vh;
        
        .module-name {
            text-align: center;
            margin-bottom: 30px;

            @media (max-width: 991.98px) {
                font-size: 2rem;
            }

            @media (max-width: 499.98px) {
                font-size: 1.5rem;
            }
        }

        .question {
            margin-bottom: 30px !important;

            h2 {
                @media (max-width: 991.98px) {
                    font-size: 2rem;
                }

                @media (max-width: 499.98px) {
                    font-size: 1.5rem;
                }
            }
        }

        .process {
            .answer {
                margin-bottom: 30px;
                position: relative;
                cursor: pointer;

                span {
                    display: block;
                    width: calc(100% - 40px);
                }
            }

            .selection {
                position: absolute;
                z-index: 2;
                right: 25px;
                top: 25px;
                width: 25px;
                height: 25px;
                border: 2px solid $purple;
                margin-left: auto;
                border-radius: 50%;
                cursor: pointer;
                padding: 5px;

                .selected {
                    width: 100%;
                    height: 100%;
                    background-color: $purple;
                    border-radius: 50%;
                }
            }
        }

        .actions {
            display: flex;
            align-items: center;
            justify-content: center;

            button:not(:last-child) {
                margin-right: 1rem;
            }
        }

        .finished {
            .results {
                > span {
                    display: block;
                    text-align: center;
                    font-size: 1.2rem;
                    font-weight: 500;

                    &:last-child {
                        margin-top: 0.1rem;
                    }
                }

                margin-bottom: 1rem;
            }

            button {
                font-size: 1rem !important;
                min-width: 150px;
            }

            .final-text {
                text-align: center;
            }
        }
    }
</style>